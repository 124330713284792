<template>
  <div>
    <Header />
    <Mobileheader />
    <div
      class="home_Cols1_backrgound"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <b-img
        src="@/assets/images/Home/Home.jpg"
        class="full-width home_img"
      ></b-img>
      <div class="sub_background">
        <h1 class="text-heading">Sindhiz Group</h1>
        <p class="text-para">Hay we are Sindhiz group with....</p>
        <b-button variant="success" pill>Click Here..</b-button>
      </div>
    </div>

    <div class="mediumbreak"></div>

    <div class="home_middle_text" data-aos="fade-up" data-aos-duration="3000">
      <h2 class="heading"><b>WELCOME</b></h2>

      <p class="para">
        Our business addresses the biggest problem <br />
        in ANY business.....Time Time to pay attention to growing a business
        or<br />
        to addressing all the areas that are involved in any retail,<br />
        hospitality, or convenience/fuel retailing business.<br />
        Explore our website and find out how RMI can he
      </p>
      <b-button variant="primary" pill>Find Out More....</b-button>
    </div>

    <div class="mediumbreak"></div>

    <div class="home_middle_text2" data-aos="fade-up" data-aos-duration="3000">
      <h2 class="heading"><b>What We Do</b></h2>

      <div class="extra_small_break1"></div>
      <div class="extra_small_break1"></div>
      <div class="extra_small_break1"></div>
      <b-container>
        <b-row>
          <b-col md="4" lg="4"
            ><h2 class="col_heading">All About You</h2>

            <p class="col_para">
              We customize each relationship with our clients to their specific
              needs. We understand and can relate to a changing competitive
              climate, changing customer needs, and your changing business and
              personal needs. RMI values our relationship with our clients. This
              isn’t a transaction to us, it’s a partnership. Our Agreement is
              able to be modified to adjust, just as your business does.
            </p></b-col
          >
          <b-col md="4" lg="4">
            <h2 class="col_heading">The Technical Stuff</h2>
            <p class="col_para">
              We take the stress out of running your business.<br /><br />

              We will staff your location & deliver excellence in customer
              service through our recruiting, staffing and operations programs.
              We develop and execute marketing programs,<br />
              category management and site level execution. Take advantage of
              large retailer type resources and programs, and local cu
            </p>

            <br />
            <b-button variant="outline-none" class="button" pill
              >+ Show More</b-button
            >
          </b-col>
          <b-col md="4" lg="4">
            <h2 class="col_heading">Our Expertise</h2>
            <p class="col_para">
              Our RMI team is assembled with decades of experience in retail,
              hospitality, business development and more. Regardless if you're
              an existing long-time operator, or an investor looking to grow,
              RMI can make your business work, without you having to!
            </p></b-col
          >
        </b-row>
      </b-container>
      <div class="extra_small_break1"></div>
      <div class="extra_small_break1"></div>
    </div>

    <div data-aos="fade-up" data-aos-duration="3000">
      <b-container>
        <b-row>
          <b-col md="6"
            ><b-img
              src="@/assets/images/Home/image_middle.png"
              class="full-width"
            ></b-img
          ></b-col>
          <b-col md="6">
            <div class="largebreak"></div>
            <div class="largebreak"></div>
            <h1 class="home_image_col_heading">LET'S BREAK IT DOWN...</h1>
            <p class="home_image_col_para">
              What exactly do we do? We "run" your store on your behalf.<br />
              Operations, Marketing, Accounting. All of it, some of it, and<br />
              everything in between. We run your store FOR you.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="largebreak"></div>

    <Footer />
  </div>
</template>

<script>
import {
  BContainer,
  BCard,
  BCardText,
  BLink,
  BRow,
  BCol,
  BImg,
  BButton,
} from "bootstrap-vue";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./components/header.vue";
import Footer from "./components/Footer.vue";
import Mobileheader from "./components/mobileheader.vue";

export default {
  components: {
    Footer,
    BContainer,
    BButton,
    BImg,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    Header,
    Mobileheader,
  },
  created() {
    AOS.init();
  },
};
</script>

<style></style>
